<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4 header">MAP - PRODUCT LIST</h1>
        </CCol>
        <CCol cols="6" class="text-right">
          <button
            type="button"
            class="btn btn-main-2 button"
            @click="addEditData(true, 0)"
          >
            ADD
          </button>
        </CCol>
      </CRow>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sortOrder)="data">
                <div v-if="data.item.sortOrder == 0">-</div>
                <div v-else>{{ data.item.sortOrder }}</div>
              </template>
              <template v-slot:cell(status)="data">
                <b-form-checkbox
                  v-model="data.item.status"
                  name="check-button"
                  switch
                  size="lg"
                  class="mb-2"
                  @change="onChangeDisplayStatus($event, data.item)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(id)="data">
                <b-button
                  variant="link"
                  class="px-1 py-0"
                  @click="
                    addEditData(false, data.item.id, data.item, data.index)
                  "
                >
                  <font-awesome-icon
                    icon="pencil-alt"
                    class="text-warning"
                    title="View"
                  />
                </b-button>
                {{ " " }} {{ " " }}
                <b-button variant="link" class="px-1 py-0">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="text-danger"
                    @click="openModalDelete(data.item)"
                  />
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      id="addEditData"
      ref="addEditData"
      title="BootstrapVue"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-header border-bottom-0">
        <h2 class="mb-1 font-weight-bold">Product Details</h2>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('addEditData')"
        >
          ×
        </button>
      </div>
      <div class="modal-body">
        <b-row>
          <b-col md="6">
            <InputText
              class="mb-4"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="name"
              isRequired
              v-model="modalData.name"
              :isValidate="$v.modalData.name.$error"
              :v="$v.modalData.name"
            />
          </b-col>
          <b-col md="6">
            <InputText
              class="mb-4"
              textFloat="Code"
              placeholder="Code"
              type="text"
              name="name"
              isRequired
              v-model="modalData.code"
              :isValidate="$v.modalData.code.$error"
              :v="$v.modalData.code"
            />
          </b-col>
          <b-col md="6">
            <InputText
              class="mb-4"
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="text"
              name="sortby"
              isRequired
              v-model="modalData.sortOrder"
              :isValidate="$v.modalData.sortOrder.$error"
              :v="$v.modalData.sortOrder"
              @keypress="isNumber($event)"
            />
          </b-col>
          <b-col md="6">
            <label class="font-weight-bold main-label">
              Status
              <span class="text-danger">*</span>
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="modalData.status"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  modalData.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              @click="$bvModal.hide('addEditData')"
              class="btn-details-set"
              :disabled="isDisable"
              >CANCEL</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveData(0)"
              :disabled="isDisable"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required } from "vuelidate/lib/validators";
export default {
  name: "user",
  components: {
    InputText,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      modalMessage: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "code",
          label: "Code",
          class: "w-100px text-nowrap",
        },
        {
          key: "name",
          label: "Name",
          class: "w-100px text-nowrap text-left",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-100px text-nowrap",
        },
        {
          key: "status",
          label: "Status",
          class: "w-100px text-nowrap",
        },
        {
          key: "id",
          label: "Action",
          class: "w-100px text-nowrap",
        },
      ],
      isDisable: false,
      modalData: {
        id: 0,
        name: "",
        sortOrder: "",
        nameCode: null,
        code: "",
        status: true,
      },
      items: [],
      isBusy: false,
    };
  },
  validations: {
    modalData: {
      name: { required },
      sortOrder: { required },
      code: { required },
    },
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/product/List`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.isBusy = false;
      }
    },
    addEditData(isAdd, id, data, index) {
      this.isAdd = isAdd;
      if (this.isAdd) {
        this.clearData();
      } else {
        this.index = index;
        this.modalData.id = data.id;
        this.modalData.name = data.name;
        this.modalData.sortOrder = data.sortOrder;
        this.modalData.code = data.code;
        this.modalData.status = data.status;
      }
      this.$refs["addEditData"].show();
    },
    onChangeDisplayStatus: async function (value, data) {
      var data = {
        id: data.id,
        name: data.name,
        sortOrder: data.sortOrder,
        code: data.code,
        status: value,
      };
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/save`,
        null,
        this.$headers,
        data
      );
      if (resData.result == 1) {
        this.getData();
      }
    },
    saveData: async function (flag) {
      this.$v.modalData.$touch();
      if (this.$v.modalData.$error) return;

      this.$v.modalData.$reset();

      var upperCase = this.modalData.code.toUpperCase();

      this.modalData.code = upperCase;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/save`,
        null,
        this.$headers,
        this.modalData
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        this.$refs["addEditData"].hide();

        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    clearData() {
      this.modalData.name = "";
      this.modalData.sortOrder = 0;
      this.modalData.code = "";
      this.modalData.id = 0;
    },
    openModalDelete(value) {
      this.requestDeleteUser.userId = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/product/delete/${this.requestDeleteUser.userId}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>       